import React, { useState } from "react";
import { Pathname } from "../../../pathname";
import { useEffect } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import OutletHeader from "../../../components/header/OutletHeader";
import { axiosInstance } from "../../../axios";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { getFormattedDate } from "../../../utils/CommonFunctions";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import { toast } from "react-toastify";
import ToggleSwitch from "../../../components/common/ToggleSwitch";
import { featureTopProduct } from "../../../Api/ProductApis";

const Product = () => {
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [fetchedProducts, setFetchedProducts] = useState();
  const [productCount, setProductCount] = useState();
  const [askForConfirmation, setAskForConfirmation] = useState(false);
  const [activeId, setActiveId] = useState();

  const limit = 8;

  useEffect(() => {
    getProducts();
  }, [page, keyword]);

  const columns = [
    {
      title: "S.No.",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 100,
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "CAS No.",
      dataIndex: "casNo",
      key: "casNo",
    },
    {
      title: "Added On",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
    {
      title: "Top Product",
      dataIndex: "topProduct",
      key: "topProduct",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const getProducts = async () => {
    try {
      const response = await axiosInstance.get(
        `/product/?page=${page}&page_size=8&search=${keyword}`
      );
      if (response?.data?.status === 200) {
        setFetchedProducts(response?.data?.data?.results);
        setProductCount(response?.data?.data?.count);
      }
    } catch (error) {}
  };

  const deleteProduct = async () => {
    try {
      const response = await axiosInstance.delete(`/product/${activeId}`);
      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
        getProducts();
      }
    } catch (error) {
      toast.info("Something went wrong!");
    }
    setAskForConfirmation(false);
  };

  const data = [];

  if (fetchedProducts?.length > 0) {
    fetchedProducts?.map((e, index) => {
      data.push({
        key: index + 1,
        serialNumber: limit * (page - 1) + (index + 1),
        productName: e?.ChemicalName ? e?.ChemicalName : "",
        casNo: e?.CasNumber ? e?.CasNumber : "",
        updatedAt: e?.created_at ? getFormattedDate(e?.created_at) : "",
        topProduct: (
          <div>
            <ToggleSwitch
              isActive={e?.is_topProduct}
              toggle={() => {
                featureTopProduct(e?.id).then((data) => {
                  if (data) {
                    toast.success("Updated Successfully.");
                    getProducts();
                  } else {
                    toast.info("Something went wrong.");
                  }
                });
              }}
            />
          </div>
        ),
        action: (
          <div className="flex gap-6 max-h-[98vh] overflow-y-scroll">
            <Link to={Pathname.DASHBOARD_PRODUCTS_EDIT} state={e}>
              <EditOutlined className="text-pink" />
            </Link>
            <DeleteOutlined
              className="text-pink"
              onClick={() => {
                setAskForConfirmation(true);
                setActiveId(e?.id);
              }}
            />
          </div>
        ),
      });
    });
  }

  return (
    <div className="w-full h-full  p-4  bg-fade ">
      <OutletHeader
        Pathname={Pathname.DASHBOARD_PRODUCTS_ADD}
        searchPlaceholder={"Search Products"}
        title={"Product List"}
        setSearchedKeyword={setKeyword}
      />

      <div className="h-[80%] border">
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: 1200, y: 480 }}
          pagination={{
            total: productCount,
            pageSize: limit,
            showSizeChanger: false,
            onChange: (page) => setPage(page),
          }}
        />
      </div>
      <ConfirmationModal
        openConfirmationModal={askForConfirmation}
        handleCancelationModal={() => {
          setAskForConfirmation(false);
        }}
        HandleConfirmationModal={deleteProduct}
        text={"Are you sure you want to delete?"}
      />
    </div>
  );
};

export default Product;
