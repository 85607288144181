import React from "react";

const ToggleSwitch = ({ isActive, toggle }) => {
  const switchStyle = {
    display: "inline-block",
    width: "1.8rem",
    height: "1.09rem",
    borderRadius: "10px",
    backgroundColor: isActive ? "#EF4136" : "gray",
    position: "relative",
    cursor: "pointer",
  };

  const sliderStyle = {
    width: "0.8rem",
    height: "0.8rem",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    top: "2px",
    left: isActive ? "0.9rem" : "2px",
    transition: "left 0.2s ease-in-out",
  };

  return (
    <div onClick={toggle} style={switchStyle}>
      <div style={sliderStyle}></div>
    </div>
  );
};

export default ToggleSwitch;
