import React from "react";

const Loader = () => {
  return (
    <div className="fixed left-0 top-0 w-full h-full flex justify-center items-center bg-[#00000036] z-50">
      <div className="rounded-full border-[0.4rem] movingLoader border-white h-14 w-14 border-dotted "></div>
    </div>
  );
};

export default Loader;
