import React from "react";
import { Pathname } from "../../pathname";
import { Link, useNavigate } from "react-router-dom";
import { removeToken } from "../../utils/localstorageutil";
import { Menu } from "antd";
import {
  AppstoreOutlined,
  AuditOutlined,
  NotificationFilled,
  FolderOutlined,
  FilterOutlined,
  UsergroupAddOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

const Sidebar = () => {
  const navigate = useNavigate();
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const items = [
    getItem(<Link to="/">Dashboard</Link>, "1", <AppstoreOutlined />, null),
    getItem("Product Management", "sub1", <NotificationFilled />, [
      getItem(
        <Link to={Pathname.DASHBOARD_PRODUCTS}>
          <FilterOutlined className="rotate-180 mr-2" />
          Product List
        </Link>,
        "p1",
        null
      ),
    ]),
    getItem("Content Management", "sub2", <FolderOutlined />, [
      // getItem(<Link to={Pathname.BLOGS}>Blogs</Link>, "g1", null),
      getItem(<Link to={Pathname.EVENTS}>Events</Link>, "g2", null),
      getItem(
        <Link to={Pathname.NEWSLETTER}>Market Insights</Link>,
        "g3",
        null
      ),
    ]),
    getItem(
      <Link to={Pathname.REPORTS}>Reports</Link>,
      "2",
      <AuditOutlined />,
      null
    ),
    getItem(
      <Link to={Pathname.OUR_TEAM}>Our Team</Link>,
      "3",
      <UsergroupAddOutlined />,
      null
    ),
  ];

  const logoutAdmin = () => {
    removeToken();
    navigate("/login");
  };
  return (
    <>
      <Menu
        style={{
          width: "100%",
        }}
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        items={items}
      />
      <div className=" absolute bottom-8 left-6">
        <div
          className="logOutBtn gap-4 flex cursor-pointer"
          onClick={logoutAdmin}
        >
          <LogoutOutlined /> Logout
        </div>
      </div>
    </>
  );
};

export default Sidebar;
