import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../../axios";
export const setUserNameAction = (state, action) => {
  state.user_name = action.payload;
};

// ---------- API CALL ----------
// Generates pending, fulfilled and rejected action types
export const login = createAsyncThunk(
  "auth/login",
  async (user, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/auth/login/`, user);
      if (response?.data?.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
