import React from "react";
import { removeToken } from "../../utils/localstorageutil";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";

const Header = () => {
  const navigate = useNavigate();
  const logoutAdmin = () => {
    removeToken();
    navigate("/login");
  };
  return (
    <>
      <div className="w-[100vw] h-[8vh] border-b-2 p-4">
        <img src={logo} alt="" className="h-full ml-6 mt-1" />
      </div>
    </>
  );
};

export default Header;
