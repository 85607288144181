import React, { useEffect, useState } from "react";
import { Pathname } from "../../../pathname";
import OutletHeader from "../../../components/header/OutletHeader";
import { Table, Typography } from "antd";
import { Link } from "react-router-dom";
import { deleteEvent, getEvents } from "./redux/eventSlice";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedDate } from "../../../utils/CommonFunctions";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";

const Events = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [askForConfirmation, setAskForConfirmation] = useState(false);
  const [activeId, setActiveId] = useState();
  const limit = 8;

  useEffect(() => {
    const args = { page, keyword };
    dispatch(getEvents(args));
  }, [page, keyword]);

  const fetchedEvents = useSelector((state) => state?.events?.events);
  const eventsCount = useSelector((state) => state?.events?.eventsCount);

  const columns = [
    {
      title: "S.No.",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 100,
    },
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      width: 500,
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Event Date",
      dataIndex: "eventDate",
      key: "eventDate",
    },
    {
      title: "Upload Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const data = [];
  const deleteEvents = async () => {
    try {
      const response = await dispatch(deleteEvent(activeId));
      const { Status, Message } = response?.payload?.data || {};
      if (Status === 200) {
        toast.success(Message);
        const args = { page, keyword };
        dispatch(getEvents(args));
      } else {
        toast.info(Message);
      }
      setAskForConfirmation(false);
    } catch (error) {}
  };

  fetchedEvents?.map((e, index) => {
    data.push({
      key: index + 1,
      serialNumber: limit * (page - 1) + (index + 1),
      title: <Typography> {e?.title}</Typography>,
      type: e?.is_upcoming ? "Upcoming" : "Past",
      eventDate: e?.dateOfEvent ? getFormattedDate(e?.dateOfEvent) : "",
      updatedAt: e?.created_at ? getFormattedDate(e?.created_at) : "",
      action: (
        <div className="flex gap-6">
          <Link to={Pathname.EVENTS_EDIT} state={e}>
            <EditOutlined className="text-pink" />
          </Link>
          <DeleteOutlined
            className="text-pink"
            onClick={() => {
              setAskForConfirmation(true);
              setActiveId(e?.id);
            }}
          />
        </div>
      ),
    });
  });

  return (
    <div className="w-full h-full  p-4  bg-fade ">
      <OutletHeader
        searchPlaceholder={"Search Events"}
        title={"Events List"}
        Pathname={Pathname.EVENTS_ADD}
        setSearchedKeyword={setKeyword}
      />

      <div className="h-[80%] border ">
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: 1200, y: 480 }}
          pagination={{
            total: eventsCount,
            pageSize: limit,
            showSizeChanger: false,
            onChange: (page) => setPage(page),
          }}
        />
      </div>
      <ConfirmationModal
        openConfirmationModal={askForConfirmation}
        handleCancelationModal={() => {
          setAskForConfirmation(false);
        }}
        HandleConfirmationModal={deleteEvents}
        text={"Are you sure you want to delete?"}
      />
    </div>
  );
};

export default Events;
