import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { Pathname } from "./pathname";
import Header from "./components/header/Header";
import Sidebar from "./components/sidebar/Sidebar";
import { fetchToken } from "./utils/localstorageutil";
import { useSelector } from "react-redux";
import Loader from "./components/common/Loader";

export const PrivateRoute = () => {
  const token = fetchToken();
  const isLoading = useSelector((state) => state?.loading?.isLoading);

  return token ? (
    <div className="relative">
      <Header />
      <div className="flex w-[100vw] h-[92vh]">
        <div className="absolute left-[-100%] w-[70%] z-20 sm:static sm:w-[25%] md:w-[16%]">
          <Sidebar />
        </div>
        <div className="w-full sm:w-[75%] md:w-[84%] overflow-y-scroll">
          <Outlet />
        </div>
      </div>
      {isLoading && <Loader />}
    </div>
  ) : (
    <Navigate to={Pathname.LOGIN} />
  );
};

export default PrivateRoute;
