import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../../axios";

const initialState = {
  news: [],
  newsCount: 0,
  isAdded: "",
  isEdited: "",
  loading: false,
  error: null,
};

export const addNews = createAsyncThunk(
  "addNews",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/newsletter/`, args);
      return response;
    } catch (error) {
      toast.info("Something went wrong!");
      return rejectWithValue(error);
    }
  }
);

export const getNews = createAsyncThunk(
  "getNews",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/newsletter/?page=${args.page}&page_size=8&search=${args.keyword}`
      );
      return response;
    } catch (error) {
      toast.info("Something went wrong!");
      return rejectWithValue(error);
    }
  }
);

export const editNews = createAsyncThunk(
  "editNews",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `/newsletter/?id=${args ? args?.id : ""}`,
        args?.data
      );
      return response;
    } catch (error) {
      toast.info("Something went wrong!");
      return rejectWithValue(error);
    }
  }
);

export const deleteNewsletters = createAsyncThunk(
  "deleteNews",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/newsletter/?id=${args}`);
      return response;
    } catch (error) {
      toast.info("Something went wrong!");
      return rejectWithValue(error);
    }
  }
);
export const handleFeatured = createAsyncThunk(
  "handleFeatured",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `/newsletter/featured/?id=${args.id}&type=${args.action}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const newsSlice = createSlice({
  name: "News",
  initialState,
  reducers: {},
  extraReducers: {
    [addNews.pending]: (state) => {
      state.loading = true;
    },
    [addNews.fulfilled]: (state, action) => {
      state.isAdded = action;
      state.loading = false;
    },
    [addNews.rejected]: (state, action) => {
      state.loading = false;
    },
    [getNews.pending]: (state) => {
      state.loading = true;
    },
    [getNews.fulfilled]: (state, action) => {
      state.news = action?.payload?.data?.data?.results;
      state.newsCount = action?.payload?.data?.data?.count;
      state.loading = false;
    },
    [getNews.rejected]: (state, action) => {
      state.loading = false;
    },
    [editNews.pending]: (state) => {
      state.loading = true;
    },
    [editNews.fulfilled]: (state, action) => {
      state.isEdited = action;
      state.loading = false;
    },
    [editNews.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default newsSlice;
