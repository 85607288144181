export let Pathname = {
  LOGIN: "/login",
  DASHBOARD: "/",
  DASHBOARD_TRANSACTIONS: "/dashboard/transactions",
  DASHBOARD_PRODUCTS: "/products",
  DASHBOARD_PRODUCTS_ADD: "/products/add",
  DASHBOARD_PRODUCTS_EDIT: "/products/edit",
  REPORTS: "/reports",
  COMPONENTS: "/components",
  BLOGS: "/blogs",
  BLOGS_ADD: "/blogs/add",
  BLOGS_EDIT: "/blogs/edit",
  EVENTS: "/events",
  EVENTS_ADD: "/events/add",
  EVENTS_EDIT: "/events/edit",
  NEWSLETTER: "/newsletter",
  NEWSLETTER_ADD: "/newsletter/add",
  NEWSLETTER_EDIT: "/newsletter/edit",
  OUR_TEAM: "/our-team",
};
