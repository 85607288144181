export const savetoken = (token) => {
  localStorage.setItem("e_u_token", token);
};

export const fetchToken = () => {
  if (
    localStorage.getItem("e_u_token") != undefined ||
    localStorage.getItem("e_u_token") != null
  ) {
    return localStorage.getItem("e_u_token");
  }
  return false;
};

export const removeToken = () => {
  localStorage.removeItem("e_u_token");
  localStorage.removeItem("refreshToken");
};
