import React from "react";
import { Modal, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const ConfirmationModal = ({
  openConfirmationModal,
  HandleConfirmationModal,
  handleCancelationModal,
  text,
}) => {
  return (
    <Modal
      open={openConfirmationModal}
      centered={true}
      closeIcon={<></>}
      footer={null}
      width={
        window.innerWidth > 850
          ? "29%"
          : window.innerWidth > 500
          ? "43%"
          : "80%"
      }
    >
      <div className="flex flex-col items-center justify-center">
        <InfoCircleOutlined className="text-[2.5rem] md:text-[3rem] mt-[0.2rem] md:mt-[1.2rem] rotate-180 text-fadeh	" />
        <Typography className="mt-1 md:mt-3 text-[1rem] md:text-[1.3rem] text-fadeh font-semibold font-sans">
          Confirmation
        </Typography>
        <Typography className="text-[0.9rem] md:text-[1.1rem] text-[#8C8C8C] font-sans">
          {text ? text : "Are you sure you want to confirm?"}
        </Typography>

        <div className="flex gap-4 mt-1 md:mt-6">
          <button
            className="font-sans my-[1rem] w-[30vw] sm:w-[8.8vw] bg-[#FFFFFF] text-[1rem] text-[#8C8C8C] border-[1.3px] border-[#8C8C8C] py-[0.5rem]  rounded-[4px]"
            type="submit"
            onClick={handleCancelationModal}
          >
            Cancel
          </button>
          <button
            className="font-sans my-[1rem] w-[30vw] sm:w-[8.8vw] bg-pink text-[1rem] text-[#FAFAFA] py-[0.5rem] rounded-[4px]"
            type="submit"
            onClick={HandleConfirmationModal}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
