import { axiosInstance } from "../axios";

export const addProduct = async (data) => {
  try {
    const resp = await axiosInstance.post(`/product/`, data);
    if (resp) {
      return resp?.data?.message;
    }
  } catch (error) {}
};

export const editProduct = async (id, data) => {
  try {
    const resp = await axiosInstance.patch(`/product/${id}`, data);
    if (resp) {
      return resp?.data?.message;
    }
  } catch (error) {}
};

export const featureTopProduct = async (id) => {
  try {
    const resp = await axiosInstance.post(`/product/top/?id=${id}`);
    if (resp?.data?.status === 200) {
      return true;
    }
  } catch (error) {
    return false;
  }
};
