import axios from "axios";
let baseURl = process.env.REACT_APP_BASE_URL;

let isRequesting = false;

export const getImageUrl = async (data) => {
  try {
    if (isRequesting) {
      return;
    }
    isRequesting = true;
    const response = await axios.post(
      `${baseURl}/home/client/static-upload/?path=product/admin-upload`,
      data
    );

    isRequesting = false;

    if (response?.data?.status === 200) {
      return response?.data?.url;
    }
  } catch (error) {
    isRequesting = false;
  }
};
