import React from "react";
import { Link } from "react-router-dom";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Typography, DatePicker, Space } from "antd";

const OutletHeader = ({
  setSearchedKeyword,
  Pathname,
  searchPlaceholder,
  title,
  isReports,
  onAdd,
  setRangeStartDate,
  setRangeEndDate,
}) => {
  const { RangePicker } = DatePicker;
  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      const context = this;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(function () {
        func.apply(context, args);
      }, delay);
    };
  }
  const handleInputChange = (e) => {
    setSearchedKeyword(e?.target?.value);
  };

  const getDate = (e) => {
    var date = new Date(e);
    const formatter = new Intl.DateTimeFormat("en-US");
    date = new Date(formatter.format(date));
    const finalDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const [month, day, year] = finalDate.split("/");
    return `${year}-${month}-${day}`;
  };

  const manageDate = (e) => {
    if (e) {
      setRangeStartDate(getDate(e[0]));
      setRangeEndDate(getDate(e[1]));
    } else {
      setRangeStartDate("");
      setRangeEndDate("");
    }
  };

  const debouncedHandleInputChange = debounce(handleInputChange, 350);

  return (
    <div className="flex justify-between items-center w-full py-3 px-4  bg-white">
      <Typography className="text-[1.2rem] font-medium">{title}</Typography>
      {!isReports ? (
        <div className="flex gap-4 w-[60%] justify-end ">
          <div className="flex justify-between items-center rounded border-[#D9D9D9] border-[1.5px] p-1 mr-4 w-[60%] text-[0.9rem] ">
            {/* Search bar------------------------------------------------------------------- */}
            <input
              type="text"
              placeholder={searchPlaceholder}
              className="p-2 outline-none h-6 w-[80%]"
              onChange={debouncedHandleInputChange}
            />
            <SearchOutlined className="cursor-pointer bg-white text-[#8C8C8C] text-[1.2rem] mr-[0.5rem]" />
          </div>
          <Link to={Pathname}>
            <div
              className=" flex items-center gap-2 bg-[#EF4136] text-white px-6 py-2 rounded-md text-[0.95rem]"
              onClick={() => {
                if (onAdd) {
                  onAdd();
                }
              }}
            >
              <PlusOutlined />
              <div className="cursor-pointer">Add New</div>
            </div>
          </Link>
        </div>
      ) : (
        <>
          {" "}
          <Space direction="vertical">
            <RangePicker onChange={manageDate} />
          </Space>
        </>
      )}
    </div>
  );
};

export default OutletHeader;
