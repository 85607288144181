import { getImageUrl } from "./CommonApi";

export const imgInputHandler = async (data) => {
  const file = data?.file;
  const e = new FormData();
  e.append("file", file?.originFileObj);
  let response;
  try {
    response = await getImageUrl(e);
    if (response) {
      return response;
    }
  } catch (error) {}
};

export const getFormattedDate = (inputDate) => {
  const date = new Date(inputDate);
  const formattedDateString = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return formattedDateString;
};
