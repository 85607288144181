// import { combineReducers } from 'redux';

// import { app } from './app/reducer';
import blogSlice from "../views/afterAuth/blogs/redux/BlogSlice";
import { dashboardSlice } from "../views/afterAuth/dashboard/redux/reducer";
import eventSlice from "../views/afterAuth/events/redux/eventSlice";
import newsSlice from "../views/afterAuth/newsletter/redux/NewsSlice";
import { loginSlice } from "../views/beforeAuth/login/redux/reducer";
import LoadingSlice from "./Slice/LoaderSlice";

export const rootReducer = {
  // app,
  dashboard: dashboardSlice.reducer,
  auth: loginSlice.reducer,
  blog: blogSlice.reducer,
  events: eventSlice.reducer,
  news: newsSlice.reducer,
  loading: LoadingSlice.reducer,
};
