import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "./redux/actionCreator";
import "../../../app.css";
import { fetchToken, savetoken } from "../../../utils/localstorageutil";
import { useNavigate } from "react-router-dom";
import { Pathname } from "../../../pathname";
import { Form, Input, Typography } from "antd";
import logo from "../../../assets/logo.svg";
import Loader from "../../../components/common/Loader";
import { toast } from "react-toastify";

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const loginUser = async (user) => {
    try {
      let data = {
        ...user,
        email: user?.email?.toLowerCase(),
      };
      setIsLoading(true);
      const res = await dispatch(login(data));
      console.log(res, "response");
      if (res?.payload?.status === 200) {
        savetoken(res?.payload?.token?.access);
        localStorage.setItem("refreshToken", res?.payload?.token?.refresh);
        navigate(Pathname.DASHBOARD);
      } else {
        toast.info(res?.payload?.response?.data?.msg);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err, "err");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const token = fetchToken();
    if (token) {
      navigate(Pathname.DASHBOARD);
    }
  }, []);

  const [form] = Form.useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const onValuesChange = (changedValues, allValues) => {
    form
      .validateFields(Object.keys(changedValues))
      .then(() => {
        setIsButtonDisabled(false);
      })
      .catch(() => {
        if (!isButtonDisabled) {
          setIsButtonDisabled(true);
        }
      });
  };
  return (
    <div className="flex justify-between w-[100vw] h-[100vh] overflow-hidden items-center">
      <div className="w-[65%] h-full border px-10 pt-20  relative">
        <img
          src="https://elchemy-website.s3.ap-south-1.amazonaws.com/products/icons/1709189323.png"
          className="absolute w-full -z-10 top-0 left-0"
        />
        <img src={logo} className="w-[5rem]" />
        <Typography className="text-[2.2rem] mt-10 font-medium text-white">
          Welcome To Website Management
        </Typography>
        {/* <Typography className="mt-4 text-[0.95rem] text-white">
          is simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book.
        </Typography> */}
      </div>
      <div className="w-[35%] h-full justify-center pt-28  px-16 bg-white ">
        <div className="w-full">
          <Typography className="text-[1.8rem] my-1">Sign In</Typography>
          <Typography className="mb-10 text-fadeh">
            Please sign in to access your account.
          </Typography>
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            onFinish={loginUser}
            autoComplete="off"
            onValuesChange={onValuesChange}
          >
            {(values, formInstance) => {
              let emailError = formInstance.getFieldError("email");
              let passwordError = formInstance.getFieldError("password");
              let isEmailTouched = formInstance.isFieldTouched("email");
              let isPasswordTouched = formInstance.isFieldTouched("password");

              console.log(emailError, passwordError);
              return (
                <>
                  {" "}
                  <Typography className="text-fadet mb-[8px] font-sans">
                    Email
                  </Typography>
                  <Form.Item
                    name="email"
                    style={{ color: "red" }}
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid Email!",
                      },
                      {
                        required: true,
                        message: "Please enter your Email!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Email"
                      className="px-4 py-3 rounded font-sans placeholder-italic"
                      // onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>
                  <Typography className="text-fadet mb-[8px] font-sans">
                    Password
                  </Typography>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      {
                        min: 8,
                        message: "Password must be at least 8 characters long!",
                      },
                      {
                        max: 20,
                        message: "Password must not exceed 20 characters!",
                      },
                      {
                        pattern:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        message:
                          "Password must contain at least one uppercase, one lowercase, one number, and one special character.",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="Enter Password"
                      className="px-4 py-3 rounded"
                      // onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Item>
                  <div className="flex w-full justify-end">
                    <Typography className="text-[#161616] text-sm cursor-pointer font-sans underline font-medium">
                      Forgot Password?
                    </Typography>
                  </div>
                  <Form.Item>
                    <button
                      type="default"
                      htmlType="submit"
                      className={`w-full  mt-10 rounded ${
                        emailError?.length === 0 &&
                        isEmailTouched &&
                        isPasswordTouched &&
                        passwordError?.length === 0
                          ? "bg-[#EF4136]"
                          : "bg-[#E6E6E6]"
                      } py-2 transition-all `}
                      // disabled={isButtonDisabled}
                    >
                      <Typography
                        className={` ${
                          emailError?.length === 0 &&
                          isEmailTouched &&
                          isPasswordTouched &&
                          passwordError?.length === 0
                            ? "text-white"
                            : "text-stone-600"
                        }  w-full text-base font-medium font-sans transition-all`}
                      >
                        Sign In
                      </Typography>
                    </button>
                  </Form.Item>
                </>
              );
            }}
          </Form>
        </div>
      </div>

      {isLoading && <Loader />}
    </div>
  );
};

export default Login;
