import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../../axios";
import { toast } from "react-toastify";

const initialState = {
  events: [],
  eventsCount: 0,
  isAdded: "",
  isEdited: "",
  loading: false,
  error: null,
};

export const addEvent = createAsyncThunk(
  "addEvent",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/events/`, args);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getEvents = createAsyncThunk(
  "getEvents",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/events/?page=${args.page}&page_size=8&search=${args.keyword}`
      );

      return response;
    } catch (error) {
      toast.info("Something went wrong!");
      return rejectWithValue(error);
    }
  }
);

export const editEvent = createAsyncThunk(
  "editEvent",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `/events/?id=${args ? args?.id : ""}`,
        args?.data
      );
      return response;
    } catch (error) {
      toast.info("Something went wrong!");
      return rejectWithValue(error);
    }
  }
);

export const deleteEvent = createAsyncThunk(
  "deleteEvent",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/events/?id=${args}`);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const eventSlice = createSlice({
  name: "Event",
  initialState,
  reducers: {},
  extraReducers: {
    [addEvent.pending]: (state) => {
      state.loading = true;
    },
    [addEvent.fulfilled]: (state, action) => {
      state.isAdded = action;
      state.loading = false;
    },
    [addEvent.rejected]: (state, action) => {
      state.loading = false;
    },
    [getEvents.pending]: (state) => {
      state.loading = true;
    },
    [getEvents.fulfilled]: (state, action) => {
      state.events = action?.payload?.data?.Data?.results;
      state.eventsCount = action?.payload?.data?.Data?.count;
      state.loading = false;
    },
    [getEvents.rejected]: (state, action) => {
      state.loading = false;
    },
    [editEvent.pending]: (state) => {
      state.loading = true;
    },
    [editEvent.fulfilled]: (state, action) => {
      state.isEdited = action;
      state.loading = false;
    },
    [editEvent.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default eventSlice;
