import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateOutlet from "./privateRoute";
import Loader from "./components/loader";
import { Pathname } from "./pathname";
import Login from "./views/beforeAuth/login/index";
import "./app.css";
import Product from "./views/afterAuth/product/Product";
import Events from "./views/afterAuth/events/Events";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Dashboard = React.lazy(() =>
  import("./views/afterAuth/dashboard/dashboard")
);
// const Orders = React.lazy(() => import("./views/afterAuth/orders/orders"));
const Components = React.lazy(() => import("./views/beforeAuth/components"));
const NotFound = React.lazy(() => import("./views/404"));
const Reports = React.lazy(() => import("./views/afterAuth/Report/Reports"));
const Blogs = React.lazy(() => import("./views/afterAuth/blogs/Blogs"));
const AddAndEditBlogs = React.lazy(() =>
  import("./views/afterAuth/blogs/AddAndEditBlogs")
);
const NewsLetter = React.lazy(() =>
  import("./views/afterAuth/newsletter/NewsLetter")
);
const AddAndEditNewsLetter = React.lazy(() =>
  import("./views/afterAuth/newsletter/AddAndEditNewsLetter")
);
const AddAndEditEvents = React.lazy(() =>
  import("./views/afterAuth/events/AddAndEditEvents")
);
const AddAndEditProduct = React.lazy(() =>
  import("./views/afterAuth/product/AddAndEditProduct")
);
const OurTeam = React.lazy(() => import("./views/afterAuth/ourTeam/OurTeam"));

export function App() {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <ToastContainer />{" "}
        <Routes>
          <Route path={Pathname.LOGIN} element={<Login />} />

          <Route path={Pathname.DASHBOARD} element={<PrivateOutlet />}>
            <Route index element={<Dashboard />} />
            {/* <Route path="orders" element={<Orders />} /> */}
            <Route path={Pathname.DASHBOARD_PRODUCTS} element={<Product />} />
            <Route
              path={Pathname.DASHBOARD_PRODUCTS_ADD}
              element={<AddAndEditProduct />}
            />
            <Route
              path={Pathname.DASHBOARD_PRODUCTS_EDIT}
              element={<AddAndEditProduct />}
            />
            <Route path={Pathname.REPORTS} element={<Reports />} />
            {/* <Route path={Pathname.BLOGS} element={<Blogs />} />
            <Route path={Pathname.BLOGS_ADD} element={<AddAndEditBlogs />} />
            <Route path={Pathname.BLOGS_EDIT} element={<AddAndEditBlogs />} /> */}
            <Route path={Pathname.EVENTS} element={<Events />} />
            <Route path={Pathname.EVENTS_EDIT} element={<AddAndEditEvents />} />
            <Route path={Pathname.EVENTS_ADD} element={<AddAndEditEvents />} />
            <Route path={Pathname.NEWSLETTER} element={<NewsLetter />} />
            <Route
              path={Pathname.NEWSLETTER_ADD}
              element={<AddAndEditNewsLetter />}
            />
            <Route
              path={Pathname.NEWSLETTER_EDIT}
              element={<AddAndEditNewsLetter />}
            />
            <Route path={Pathname.OUR_TEAM} element={<OurTeam />} />

            <Route path="*" element={<NotFound />} />
          </Route>

          <Route path={Pathname.COMPONENTS} element={<Components />} />
          <Route path="*" component={NotFound} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
