import axios from "axios";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export const axiosInstance = axios.create({
  baseURL: `${REACT_APP_BASE_URL}`,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("e_u_token");
    if (token && config.url !== "/auth/refresh/") {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const accessToken = await generateAccesstoken();
        if (accessToken) {
          localStorage.setItem("e_u_token", accessToken);
        } else {
          handleRefreshTokenError();
        }
        return axiosInstance(originalRequest);
      } catch (error) {
        handleRefreshTokenError();
      }
    }
    return Promise.reject(error);
  }
);
async function generateAccesstoken() {
  try {
    const response = await axiosInstance.post("/auth/refresh/", {
      refresh: localStorage.getItem("refreshToken"),
    });
    if (response?.data?.status === 200) {
      return response.data.access_token;
    } else {
      handleRefreshTokenError();
    }
  } catch (error) {
    handleRefreshTokenError();
  }
}

function handleRefreshTokenError() {
  localStorage.clear();
  window.location.reload(true);
}
