import { createSlice } from "@reduxjs/toolkit";
import initialState from "./state";

import { setUserNameAction, login } from "./actionCreator";

export const loginSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserName: setUserNameAction,
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.user.token = action?.payload?.token;
      state.error = "";
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.error?.message;
    });
  },
});

export const { setUserName } = loginSlice.actions;

export default loginSlice.reducer;
