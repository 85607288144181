import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../../axios";

const initialState = {
  blogs: [],
  blogsCount: 0,
  isAdded: "",
  isEdited: "",
  loading: false,
  error: null,
  isDeleted: true,
};

export const addBlog = createAsyncThunk(
  "addBlog",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/blog/`, args);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getBlogs = createAsyncThunk(
  "getBlog",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/blog/?page=${args.page}&page_size=8&search=${args.keyword}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const editBlog = createAsyncThunk(
  "editBlog",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `/blog/?id=${args ? args?.id : ""}`,
        args?.data
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteBlog = createAsyncThunk(
  "deleteBlog",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/blog/?id=${args}`);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const blogSlice = createSlice({
  name: "Blog",
  initialState,
  reducers: {},
  extraReducers: {
    [addBlog.pending]: (state) => {
      state.loading = true;
    },
    [addBlog.fulfilled]: (state, action) => {
      state.isAdded = action;
      state.loading = false;
    },
    [addBlog.rejected]: (state, action) => {
      state.loading = false;
    },
    [getBlogs.pending]: (state) => {
      state.loading = true;
    },
    [getBlogs.fulfilled]: (state, action) => {
      state.blogs = action?.payload?.data?.Data?.results;
      state.blogsCount = action?.payload?.data?.Data?.count;
      state.loading = false;
    },
    [getBlogs.rejected]: (state, action) => {
      state.loading = false;
    },
    [editBlog.pending]: (state) => {
      state.loading = true;
    },
    [editBlog.fulfilled]: (state, action) => {
      state.isEdited = action;
      state.loading = false;
    },
    [editBlog.rejected]: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
    },
    [deleteBlog.pending]: (state) => {},
    [deleteBlog.fulfilled]: (state, action) => {
      state.isDeleted = true;
    },
    [deleteBlog.rejected]: (state, action) => {
      state.isDeleted = false;
    },
  },
});

export default blogSlice;
